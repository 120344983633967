import React from 'react';
import ReactDOM from 'react-dom/client';
import { PmuContext } from './PmuContext';
import AppRoot from '@eogile/agrements-common/src/AppRoot';
import baseTheme from '@eogile/agrements-common/src/baseTheme';
import { initializeSentry } from '@eogile/agrements-common/src/config/sentry/sentryInitializer';

// noinspection JSIgnoredPromiseFromCall
initializeSentry();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot appContext={PmuContext} theme={baseTheme} />);
